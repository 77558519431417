import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=73c8a7e5&scoped=true&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"
import style0 from "./Form.vue?vue&type=style&index=0&id=73c8a7e5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73c8a7e5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Source\\openproject\\go-care\\Frontend\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73c8a7e5')) {
      api.createRecord('73c8a7e5', component.options)
    } else {
      api.reload('73c8a7e5', component.options)
    }
    module.hot.accept("./Form.vue?vue&type=template&id=73c8a7e5&scoped=true&", function () {
      api.rerender('73c8a7e5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/sistema/perfil/Form.vue"
export default component.exports