<template>
  <Layout>
    <PageHeader :title="$route.meta.title" :items="$route.meta.breadcrumbs" />

    <b-overlay
      :show="loading"
      variant="light"
      :opacity="0.85"
    >
      <div class="card">
        <div class="card-body">

          <div class="d-flex justify-content-end" v-if="$slots.dropdownMenu">
            <div>
              <slot name="dropdownMenu" />
            </div>
          </div>

          <slot />

        </div>

        <div class="card-footer" v-if="$slots.footer">
          <slot name="footer" />
        </div>

      </div>
    </b-overlay>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
export default {
  name: "CardForm",
  components: {
    Layout, PageHeader
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
