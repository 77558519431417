var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$route.meta.title,
          items: _vm.$route.meta.breadcrumbs
        }
      }),
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, variant: "light", opacity: 0.85 } },
        [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.$slots.dropdownMenu
                  ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _c("div", [_vm._t("dropdownMenu")], 2)
                    ])
                  : _vm._e(),
                _vm._t("default")
              ],
              2
            ),
            _vm.$slots.footer
              ? _c("div", { staticClass: "card-footer" }, [_vm._t("footer")], 2)
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }