import { api, param2query } from "./api.service";

export const getAll = (params) => {
  return api.get('perfil/listar?' + param2query(params));
};

export const getPerfil = (id) => {
  return api.get('perfil/' + id);
};

export const criarPerfil = (model) => {
  return api.post('perfil', model)
}

export const atualizarPerfil = (model) => {
  return api.put('perfil', model)
}

export const getAllUsuarios = (id) => {
  return api.get('perfil/listar-usuarios/' + id);
};

export const pesquisarUsuarioSelect = (query) => {
  return api.get('perfil/buscar-usuarios?filtro=' + query);
}

export const adicionarUsuarioPerfil = (usuarioId, perfilId) => {
  return api.post('perfil/adicionar-usuario', {
    usuarioId, perfilId,
    ativo: true, isCriar: true
  })
}

export const alterarUsuarioPerfil = (usuarioId, perfilId, ativo) => {
  return api.put('perfil/alterar-usuario', {
    usuarioId, perfilId, ativo, isCriar: false}
  )
}

export const excluirUsuarioPerfil = (usuarioId, perfilId) => {
  return api.delete('perfil/excluir-usuario?' + param2query({ usuarioId, perfilId}) 
  )
}

export const getModuloTree = (params) => {
  return api.get('perfil/modulo/tree?' + param2query(params));
};

export default { getAll };
