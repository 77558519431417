import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get('usuario/listar?' + param2query(params), cancelToken);
};

export const getUsuario = (id) => {
  return api.get('usuario/' + id);
};

export const criarUsuario = (model) => {
  return api.post('usuario', model)
}

export const atualizarUsuario = (model) => {
  return api.put('usuario', model, 
  {
    headers : {
      "Content-Type" : "multipart/form-data"
    }
  })
}

export const getUsuarioAssinatura = (id) => {
  return api.get('resources/assinatura/' + id);
}

export const atualizarSenha = (novaSenha) => {
  return api.post('usuario/atualizar-minha-senha', { novaSenha })
}

export const alterarSenha = (id, novaSenha) => {
  return api.post('usuario/atualizar-senha', { id, novaSenha })
}

export const getLabelsConvenios = (params) => {
  return api.get('usuario/labels-convenios?' + param2query(params));
};

export const getLabelsHospitais = (params) => {
  return api.get('usuario/labels-hospitais?' + param2query(params));
};

export const getLabelsPerfis = (params) => {
  return api.get('usuario/labels-perfis?' + param2query(params));
};

export const getAllAtribuicao = (params, cancelToken) => {
  return api.get('atribuicao/listar?' + param2query(params), cancelToken);
};

export const getAllRepresentacao = (params, cancelToken) => {
  return api.get('representacao/listar?' + param2query(params), cancelToken);
};

export const restaurarSenha = (email) => {
  return api.post('login/restaurar-senha', { email })
}

export const recuperarSenha = (email, codigo, novaSenha) => {
  return api.post('login/recuperar-senha', { email, codigo, novaSenha })
}

export default { getAll };
