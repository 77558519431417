var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.submeter }
                    },
                    [_vm._v(_vm._s(_vm.model.id == 0 ? "Criar" : "Alterar"))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      _vm.$router.push({ name: "sistema.perfil.lista" })
                    }
                  }
                },
                [_vm._v("Voltar")]
              ),
              _vm.model.id > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-info px-4",
                      attrs: { type: "button", title: "Usuários" },
                      on: {
                        click: function() {
                          _vm.$router.push({
                            name: "sistema.perfil.usuarios.lista",
                            params: {
                              id: _vm.model.id,
                              backTo: _vm.$router.currentRoute.path
                            }
                          })
                        }
                      }
                    },
                    [_c("i", { staticClass: "uil uil-users-alt" })]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            attrs: {
              name: "descricao",
              disabled: _vm.disabled,
              "data-vv-as": "Descrição",
              label: "Descrição",
              modelErrors: _vm.errors,
              cols: 6
            },
            model: {
              value: _vm.model.descricao,
              callback: function($$v) {
                _vm.$set(_vm.model, "descricao", $$v)
              },
              expression: "model.descricao"
            }
          }),
          _vm.$route.params.id > 0
            ? _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "fieldset",
                  { staticClass: "form-group mb-3 form-group-input" },
                  [
                    _c(
                      "legend",
                      { staticClass: "bv-no-focus-ring col-form-label pt-0" },
                      [_vm._v(" Representação ")]
                    ),
                    _c("div", { staticClass: "representacao" }, [
                      _vm._v(_vm._s(_vm.model.representacao))
                    ])
                  ]
                )
              ])
            : _c("f-selectsearch", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{required: true}"
                  }
                ],
                ref: "representacaoId",
                attrs: {
                  label: "Representação",
                  name: "representacaoId",
                  "data-vv-as": "Representação",
                  labelField: "nome",
                  modelErrors: _vm.errors,
                  "search-function": _vm.getAllRepresentacao,
                  "remove-options-on-select": false,
                  "hide-selected": false,
                  cols: 6
                },
                on: { select: _vm.selecionarRepresentacao },
                model: {
                  value: _vm.model.representacaoId,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "representacaoId", $$v)
                  },
                  expression: "model.representacaoId"
                }
              }),
          _c("f-switch", {
            attrs: {
              name: "ativo",
              disabled: _vm.disabled,
              cols: 12,
              label: _vm.model.ativo ? "Ativo" : "Inativo"
            },
            model: {
              value: _vm.model.ativo,
              callback: function($$v) {
                _vm.$set(_vm.model, "ativo", $$v)
              },
              expression: "model.ativo"
            }
          }),
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "fieldset",
              { staticClass: "form-group mb-3 form-group-input" },
              [
                _c(
                  "legend",
                  { staticClass: "bv-no-focus-ring col-form-label pt-0" },
                  [_vm._v(" Módulos ")]
                ),
                _vm.model.representacaoId > 0
                  ? _c("tree", {
                      ref: "tree",
                      staticClass: "tree--small",
                      attrs: { data: _vm.allModulos, options: _vm.treeOptions },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              return _c(
                                "span",
                                { staticClass: "tree-text" },
                                [
                                  [
                                    _c("i", { class: " " + node.data.icon }),
                                    _vm._v(" " + _vm._s(node.text) + " ")
                                  ]
                                ],
                                2
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        2356583720
                      )
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "alert alert-warning",
                        attrs: { role: "alert" }
                      },
                      [_vm._v("Selecione a representação")]
                    )
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }